import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import bulmaCollapsible from '@creativebulma/bulma-collapsible/src/js/index.js';
import Layout from '../components/layout';
import { TemplateProductQuery } from '../../graphql-types';

const Product = ({ data }: { data: TemplateProductQuery }): JSX.Element => {
  const { markdownRemark, zendeskHelpCenterCategory } = data;
  if (!markdownRemark) {
    throw new Error('markdownRemark が取得できません。');
  }
  const { frontmatter, html } = markdownRemark;
  if (!html) {
    throw new Error('markdownRemark.html が取得できません。');
  }
  if (!zendeskHelpCenterCategory) {
    throw new Error('zendeskHelpCenterCategory が取得できません。');
  }
  const { childrenZendeskHelpCenterSection } = zendeskHelpCenterCategory;
  if (!childrenZendeskHelpCenterSection) {
    throw new Error('zendeskHelpCenterCategory.childrenZendeskHelpCenterSection が取得できません。');
  }
  useEffect(() => {
    bulmaCollapsible.attach('.is-collapsible');
  }, []);
  return (
    <Layout seo={{ title: frontmatter?.name ?? '' }}>
      <section className="section">
        <div className="container">
          <h1 className="title">{frontmatter?.name}</h1>
          <h2 className="subtitle is-6">{frontmatter?.catchphrase}</h2>

          <div
            className="block"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <div className="block">
            <h2 id="help" className="subtitle is-4">ヘルプ</h2>

            <div id="accordion-help-center">
              {childrenZendeskHelpCenterSection.map((section) => {
                if (!section || !section.childrenZendeskHelpCenterArticle) {
                  return (<></>);
                }
                return (
                  <div className="mb-6">
                    <h3 className="subtitle is-6 has-text-weight-bold">{section.name}</h3>
                    {section.childrenZendeskHelpCenterArticle.map((article) => (
                      <article className="message">
                        <div className="message-header">
                          <a href={`#collapsible-message-accordion-${article?.articleId}`} data-action="collapse">{article?.title}</a>
                        </div>
                        <div id={`collapsible-message-accordion-${article?.articleId}`} className="message-body is-collapsible" data-parent="accordion-help-center">
                          <div
                            className="message-body-content content"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: article?.body ?? '' }}
                          />
                        </div>
                      </article>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Product;

export const pageQuery = graphql`
  query TemplateProduct ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        catchphrase
      }
    }
    zendeskHelpCenterCategory(categoryId: { eq: 360004536531 }) {
      id
      name
      childrenZendeskHelpCenterSection {
        categoryId
        name
        childrenZendeskHelpCenterArticle {
          articleId
          body
          title
        }
      }
    }    
  }
`;
